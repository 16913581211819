<template>
  <b-card>
    <b-tabs>
      <b-tab title="Todos os usuários">
        <datatable
          no-body
          ref="usersDatatable"
          :customFields="userCustomFields"
          serviceUrl="admin/usuarios"
          :showExportToExcell="true"
          :exportToExcellAction="downloadUsersXlsx"
        >
          <template #table-header>
            <div class="d-flex justify-content-between align-items-center">
              <h3 class="mb-0"></h3>
              <div class="d-flex">
                <b-input-group class="input-group-merge mr-2">
                  <b-form-input v-model="search" class="form-control-merge" placeholder="Buscar" />
                  <b-input-group-append is-text>
                    <feather-icon icon="SearchIcon" size="18" />
                  </b-input-group-append>
                </b-input-group>
                <v-select
                  v-model="selectedStatus"
                  :options="statuses"
                  class="assignor-selector d-flex flex-column mr-2"
                  placeholder="Status"
                >
                </v-select>
                <b-button
                  variant="outline-warning"
                  class="bg-light-warning"
                  @click="$bvModal.show('newInviteModal')"
                >
                  <span class="text-dark font-weight-bolder text-nowrap"> Convidar Usuário </span>
                </b-button>
              </div>
            </div>
          </template>
        </datatable>
      </b-tab>
      <b-tab title="Convites">
        <datatable
          no-body
          ref="inviteDatatable"
          :customFields="inviteCustomFields"
          serviceUrl="backoffice/convites"
        >
          <template #table-header>
            <div class="d-flex justify-content-between align-items-center">
              <h3 class="mb-0"></h3>
              <div class="d-flex">
                <b-input-group class="input-group-merge mr-2">
                  <b-form-input v-model="search" class="form-control-merge" placeholder="Buscar" />
                  <b-input-group-append is-text>
                    <feather-icon icon="SearchIcon" size="18" />
                  </b-input-group-append>
                </b-input-group>
                <b-button
                  variant="outline-warning"
                  class="bg-light-warning"
                  @click="$bvModal.show('newInviteModal')"
                >
                  <span class="text-dark font-weight-bolder text-nowrap"> Convidar Usuário </span>
                </b-button>
              </div>
            </div>
          </template>
        </datatable>
      </b-tab>

      <new-invite-modal @invite-created="$refs.inviteDatatable.refresh()" />
      <edit-invite-modal
        @invite-updated="$refs.inviteDatatable.refresh()"
        :currentInvite="currentInvite"
      />
      <edit-user-modal
        @invite-updated="$refs.usersDatatable.refresh()"
        :currentUser="currentUser"
      />
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BModal,
  BTable,
  BBadge,
  BButton,
  BTooltip,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import Datatable from '@/views/common/crud/components/Datatable'
import vSelect from 'vue-select'
import {
  userStatusBadge,
  brazilianFormattedDate,
} from '@core/comp-functions/data_visualization/datatable'
import NewInviteModal from '@/views/admin/users/components/NewInviteModal'
import EditInviteModal from '@/views/admin/users/components/EditInviteModal'
import EditUserModal from '@/views/admin/users/components/EditUserModal'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import downloadFile from '@/utils/download-file'

export default {
  name: 'UsersIndex',
  components: {
    BTab,
    BTabs,
    BCard,
    BTable,
    BModal,
    BBadge,
    BButton,
    vSelect,
    BTooltip,
    BFormInput,
    Datatable,
    BInputGroup,
    BFormCheckbox,
    NewInviteModal,
    EditUserModal,
    EditInviteModal,
    BInputGroupAppend,
  },
  data() {
    return {
      search: '',
      selectedStatus: null,
      selectedAssignor: null,
      statuses: [
        { value: 'ativo', label: 'Ativo' },
        { value: 'bloqueado', label: 'Bloqueado' },
      ],
      currentUser: {},
      currentInvite: {},
      userCustomFields: [
        {
          label: 'Nome',
          key: 'nome',
        },
        {
          label: 'Celular',
          key: 'telefone',
        },
        {
          label: 'Email',
          key: 'email',
        },
        {
          label: 'Tipo de usuário',
          key: 'tipo_usuario',
        },
        {
          label: 'Cedente',
          key: 'nome_cedente',
        },
        {
          label: 'Status',
          key: 'status',
          formatter: userStatusBadge,
        },
        {
          label: '',
          key: 'userActions',
          editAction: (user) => {
            this.currentUser = user
            this.$bvModal.show('editUserModal')
          },
          action: (payload) => {
            this.$swal({
              title: 'Inativar usuário?',
              html: `Atenção! <br>
                       Você tem certeza que deseja inativar este usuário?`,
              showCancelButton: true,
              confirmButtonText: 'Inativar usuário',
              cancelButtonText: 'Cancelar',
            }).then(async (result) => {
              if (result.value) {
                try {
                  await this.$store.dispatch('admin/blockUser', [payload.id])
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Usuário bloqueado.',
                      icon: 'CheckCircleIcon',
                      variant: 'dark',
                      text: `O usuário ${payload.nome} foi bloqueado com sucesso!`,
                    },
                  })
                  this.$refs.usersDatatable.refresh()
                } catch (error) {
                  this.$swal({
                    title: 'Erro ao bloquear usuário',
                    text: 'Ocorreu um erro ao bloquear o usuário. Tente novamente mais tarde.',
                    icon: 'error',
                  })
                }
              }
            })
          },
          actionUnblock: (payload) => {
            this.$swal({
              title: 'Ativar usuário?',
              html: `Atenção! <br>
                       Você tem certeza que deseja ativar este usuário?`,
              showCancelButton: true,
              confirmButtonText: 'Ativar usuário',
              cancelButtonText: 'Cancelar',
            }).then(async (result) => {
              if (result.value) {
                try {
                  await this.$store.dispatch('admin/unblockUser', [payload.id])
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Usuário ativado.',
                      icon: 'CheckCircleIcon',
                      variant: 'dark',
                      text: `O usuário ${payload.nome} foi ativado com sucesso!`,
                    },
                  })
                  this.$refs.usersDatatable.refresh()
                } catch (error) {
                  this.$swal({
                    title: 'Erro ao ativar usuário',
                    text: 'Ocorreu um erro ao ativar o usuário. Tente novamente mais tarde.',
                    icon: 'error',
                  })
                }
              }
            })
          },
        },
      ],
      inviteCustomFields: [
        {
          label: 'Nome',
          key: 'nome',
        },
        {
          label: 'Celular',
          key: 'telefone',
        },
        {
          label: 'Data de envio',
          key: 'created_at',
          formatter: brazilianFormattedDate,
        },
        {
          label: 'Email',
          key: 'email',
        },
        {
          label: 'Cedente',
          key: 'nome_cedente',
        },
        {
          label: '',
          key: 'inviteActions',
          openModal: 'editInviteModal',
          action: (item) => {
            this.$swal({
              title: 'Excluir convite?',
              text: `Você tem certeza que deseja excluir este convite e seus dados?`,
              showCancelButton: true,
              confirmButtonText: 'Excluir convite',
              cancelButtonText: 'Cancelar',
            }).then(async (result) => {
              if (result.value) {
                try {
                  await this.$store.dispatch('admin/deleteInvite', item.id)
                  this.$swal({
                    title: 'Convite excluído com sucesso!',
                    icon: 'success',
                  })
                  this.$refs.inviteDatatable.refresh()
                } catch (error) {}
              }
            })
          },
          editAction: (invite) => {
            this.currentInvite = invite
            this.$bvModal.show('editInviteModal')
          },
        },
      ],
    }
  },
  async mounted() {},
  methods: {
    async downloadUsersXlsx() {
      try {
        const { data } = await this.$store.dispatch(
          'admin/exportUsers',
          this.$refs.usersDatatable.params,
        )
        downloadFile(data, 'usuários.xlsx', 'text/xlsx')
      } catch (error) {
        this.$notify({
          type: 'error',
          text: 'Ocorreu um erro ao tentar baixa o arquivo.',
        })
      }
    },
  },
  computed: {
    params() {
      let params = {}

      if (this.selectedAssignor) params.cedente_id = this.selectedAssignor.id
      if (this.selectedStatus) params.status = this.selectedStatus.value

      if (this.datatableFilterValues) params = { ...params, ...this.datatableFilterValues }

      return params
    },
  },
  watch: {
    params(newValue, oldValue) {
      if (newValue !== oldValue) this.$refs.usersDatatable.updateFilterValues(newValue)
    },
  },
}
</script>

<style>
.assignor-selector {
  min-width: 240px;
}
</style>
